body {
    font-family: 'Cormorant Infant';
    font-size: 18px;
}

.logo {
    position: absolute;
    top: 25px;
    left: 25px;
    width: 150px;
    height: 150.5px;
    transform: rotate(-5deg);
}

.emptyCol {
    position: relative;
    width: 40%;
    height: calc(60% - 20px);
    margin-right: 5%;
    /* margin-right: 100px; */
}

.subCol {
    position: absolute;
    padding: 10px;
    width: 100%;

    background: rgba(255, 255, 255, .4);
    border-radius: 15px;
    background-color: rgba(255, 255, 255, .6);  
    backdrop-filter: blur(8px);
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); 

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.podcastCol {
    position: relative;
    padding: 10px;
    width: 50%;
    height: calc(100% - 20px);
    background: rgba(255, 255, 255, .4);
    border-radius: 15px;
    background-color: rgba(255, 255, 255, .6);  
    backdrop-filter: blur(8px);
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); 
}

.mountains {
    position: fixed;
    top: 150px;
    bottom: 0px;
    opacity: .5;
    overflow: hidden;
    user-select: none;
}

.flexContainer {
    position: absolute;
    left: 200px;
    /* left: 250px; */
    top: 175px;
    width: calc(100% - 350px);
    /* width: 100%; */
    height: calc(100% - 200px);
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
}

.title {
    position: absolute;
    top: 58px;
    z-index: 10;

    display: flex;
    justify-content: center;
    text-align: center;
    /* font-family: 'Petit Formal Script', cursive; */
    font-family: cinzel, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 80px;
    letter-spacing: 0.01em;
    color:rgb(97, 97, 97);
}

.subtitle {
    position: absolute;
    width: calc(100% - 20px);
    height: 25px;
    top: 25px;

    /* font-family: 'Sofia', cursive; */
    /* font-family: 'Unica One'; */
    font-family: 'Prata';
    font-style: normal;
    /* font-weight: 800; */
    font-size: 30px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color:rgb(97, 97, 97);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    color: rgb(61, 107, 156);
}

.about {
    position: absolute;
    top: 70px;
    width: calc(100% - 20px);

    /* font-family: 'Cormorant Infant'; */
    font-family: 'Cormorant Infant';
    font-style: normal;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color:rgb(97, 97, 97);
    display: flex;
    /* align-items: center;  */
    justify-content: center;
    text-align: center;
}

.about_schedule {
    position: absolute;
    top: 10px;
    left: 20px;
    width: calc(100% - 20px);

	font-family: 'Cormorant Infant';
    font-style: normal;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color:rgb(97, 97, 97);
    text-align: left;
}

.about_name {
    position: absolute;
    top: 60px;
    left: 40px;
    width: calc(100% - 40px);

	font-family: 'Cormorant Infant';
    font-style: normal;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color:rgb(97, 97, 97);
    text-align: left;
}

.about_question {
    position: relative;
    top: 0px;
    left: 10px;
    width: 100%;
    text-align: center;

	font-family: 'Cormorant Infant';
    font-style: normal;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color:rgb(97, 97, 97);
}

.q_container {
    position: absolute;
    width: 100%;

    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-row-gap: 40px;
    align-self: center;
}

.button_container {
    position: relative;
    left: 10px;
    bottom: 15px;
    width: 100%;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.button_container_quick {
    position: absolute;
    top: 95px;
    height: calc(100% - 65px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    flex-direction: column;
    overflow-y: scroll;
    width: 100%;
}

.backArrow {
    position: absolute;
    top: 20px;
    left: 20px;
    width: fit-content;
    height: 30px;
    cursor: pointer;
    z-index: 5;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #5AC8FA;
    font-weight: 700;
    font-size: 20px;
}

.question {
    position: absolute;
    height: 50px;
    width: 50%;
    min-width: 20px;

    background-color: aquamarine;
    border-radius: 15px;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    font-family: 'Cormorant Infant';
    font-style: normal;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color:rgb(97, 97, 97);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .18); 
    cursor: pointer;
}

.popup_input {
    position: absolute;

    height: 90px;
    width: calc(100% - 64px);
    left: 19px;
    top: 200px;
    padding: 12px;

    resize: none;
    
    background: #F4F1F1;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    border: none;

    font-family: 'Cormorant Infant';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
}

.popup_input:focus::placeholder {
    font-family: 'Cormorant Infant';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    
    color: #979797 !important;
}

.popup_input:focus {
    background: #FFFFFF !important;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.14) !important;
    border-radius: 6px !important;
    outline: none;
}

.popup_input::placeholder {
    font-family: 'Cormorant Infant';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    
    color: #979797;
}

.popup_input:hover {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
}

.popup_input:hover::placeholder {
    color: #1455A6;
}

.warning {
    position: absolute;
    bottom: 10px;
    width: -moz-fit-content;
    width: 100%;
    font-family: 'Cormorant Infant';
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 15px;
    display: flex;
    justify-content: center;
    color: #FF8062;
    text-align: center;
}

#outer {

    width: 400px;
    height: 400px;
    top: -100px;
    left: -400px;
    background: #F6F7F9;
    border-radius: 24px;
    overflow: scroll;
}

#title {
    position: absolute;
    left: 0%;
    width: fit-content;
    top: 20px;
    font-family: 'Petit Formal Script', cursive;
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #2B3443;
}

#description {
    position: absolute;
    width: calc(100% - 20px);
    height: 34px;
    left: 10px;
    top: 101px;

    font-family: 'Cormorant Infant';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    /* or 169% */

    text-align: center;

    color: #2B3443;

    mix-blend-mode: normal;
    opacity: 0.8;
}

#submit_button {
    position: absolute;
    width: 226px;
    height: 46px;
    left: calc(50% - 113px);
    background: #5BE7C3;
    border-radius: 100px;
    top: 325px;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    /* font-family: 'Cormorant Infant'; */
    font-style: normal;
    /* font-weight: 800; */
    font-size: 20px;
    line-height: 20px;

    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: #FFFFFF;
}

#submit {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 15px;

    font-family: 'Cormorant Infant';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;

    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: capitalize;

    /* grayscale / white */

    color: #FFFFFF;
    cursor: pointer;
}

#request {
    position: fixed;
    width: 70px;
    height: 70px;
    right: 34px;
    bottom: 20px;
    cursor: pointer;

}

#exit {
    position: absolute;
    width: 20px;
    cursor: pointer;
    top: 25px;
    right: 25px;
    z-index: 5;
}

.grid {
    position: relative;
    top: 70px;
    width: 100%;

    display: grid;
    grid-template-columns: auto;
    grid-row-gap: 24px;
    align-self: center;
}

.row {
    position: relative;
    width: calc(100% - 10px);
    height: 90px;
    background: rgb(237, 220, 252);
    padding: 5px;
    border-radius: 15px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .18); 
}

.name {
    position: absolute;
    left: 10px;

    /* font-family: 'Sofia', cursive; */
    /* font-family: 'EB Garamond', serif; */
    font-family: 'Prata';
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
}

.download {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.pdf {
    position: relative;
    width: fit-content;
    height: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

}

.play {
    position: absolute;
    right: 40px;
    width: 20px;
    height: 20px;
}

.audio {
    position: absolute;
    bottom: 5px;
    width: calc(100% - 10px);
    height: 40px;
    outline: none;
}

.date {
    position: absolute;
    left: 10px;
    top: 36px;

    /* font-family: 'Sofia', cursive; */
    /* font-family: 'EB Garamond', serif; */
    font-family: 'Prata';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 12px;
}

.emptyRow {
    position: relative;
    width: calc(100% - 10px);
    height: 15px;
    padding: 5px;
}


.fair_txt {
    position: absolute;
    width: fit-content;
    height: 61px;
    left: 181px;
    top: calc(50% - 143px);
    
    font-family: 'Sofia', cursive;
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    line-height: 61px;
    letter-spacing: 0.01em;
    
    /* UniFlow Intrege */
    
    color: #4A4A68;
}

.icon_box {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 111px;
    top: calc(50% - 140px);
    
    background: #FFFFFF;
    box-shadow: 0px 0px 10px #C9D9E8;
    border-radius: 10px;
}

.icon {
    position: absolute;
    top:13px;
    left:13px;
    width: 24px;
    height: 24px;
}

.desc {
    position: absolute;
    width: 396px;
    height: 42px;
    left: 52px;
    top: calc(50% - 30px);
    
    font-family: 'Cormorant Infant';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.02em;
    
    /* UniFlow Intrege */
    
    color: #4A4A68;
}

.save {
    position: relative;
    width: 185px;
    height: 42px;
    left: calc(50% - 92.5px);
    top: calc(50% + 110px);
    
    /* UniFlow Green */
    
    background: #5BE7C3;
    border-radius: 8px;
    font-family: 'Cormorant Infant';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 16px;
    /* or 114% */
    
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    
    color: #FFFFFF;
    cursor: pointer;
}

.p_warning {
    position: absolute;
    top: calc(50% - 7px);
    width: -moz-fit-content;
    width: 100%;
    font-family: 'Cormorant Infant';
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 15px;
    display: flex;
    justify-content: center;
    color: #FF8062;
    text-align: center;
}

.mainGrid {
    position: absolute;
    width: 100%;
    top: 0px;

    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 140px 310px auto auto auto auto auto;
    grid-row-gap: 40px;
    align-self: center;
    justify-content: center;
    background: linear-gradient(#e66465, #9198e5);
}

.adminTxt {
    position: absolute;
    width: 208px;
    height: 45px;
    left: 0px;
    top: 30px;
    width: 100%;
    text-align: center;
    font-family: 'Sofia', cursive;
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    line-height: 61px;
    letter-spacing: 0.01em;
    color: #4A4A68;
}

.welcomeSara {
    position: absolute;
    width: 208px;
    height: 45px;
    left: 0px;
    top: 80px;
    width: 100%;
    text-align: center;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 61px;
    letter-spacing: 0.01em;
    color: #4A4A68;
}

.adminGrid {
    position: absolute;
    top: 170px;
    width: 75%;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto auto auto auto;
    left: 12.5%;
}

.popup_input_grid {
    position: relative;

    height: 10px;
    width: calc(100% - 64px);
    padding: 12px;
    margin: 10px;
    left: 10px;

    resize: none;
    
    background: #F4F1F1;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    border: none;

    font-family: 'Cormorant Infant';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
}

.popup_input_grid:focus::placeholder {
    font-family: 'Cormorant Infant';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    
    color: #979797 !important;
}

.popup_input_grid:focus {
    background: #FFFFFF !important;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.14) !important;
    border-radius: 6px !important;
    outline: none;
}

.popup_input_grid::placeholder {
    font-family: 'Cormorant Infant';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    
    color: #979797;
}

.popup_input_grid:hover {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
}

.popup_input_grid:hover::placeholder {
    color: #1455A6;
}

.dateGrid {
    position: absolute;
    width: 40%;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    left: 30%;
}

p {
    position: relative;
    margin-left: 20px;
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 15px;
}

.scroll {
    /* min-height: 830px; */
    overflow: scroll;
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: left;
}

.fakeLink {
    cursor:pointer;
    color: #b19cd9;
    text-decoration:underline;
}

.quick_button {
    position: relative;
    justify-content: center;
    padding: 10px;
    align-items: center;
    text-align: center;
    cursor:pointer;
    color: #a386d9;
    width: 80%;
    height: fit-content;
    font-size: 28px;
    font-weight: '800';
    background-color: #cae4f1;
    margin: 15px 0;
    border-radius: 13px;
    box-shadow: 0 0 1rem 0 rgb(0 0 0 / 20%);
}

.announcementsContainer {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    top: 52px;
    overflow-y: scroll;
    flex-wrap: wrap;
    margin: 5px 15px;
    height: calc(100% - 75px);
}

.announcementOne {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    height: 40px;
    width: 100%;
}

.announcementText {
    position: relative;
    width: fit-content;
    height: 20px;
    margin-left: 10px;
}

.pdf_container {
    height: 90%;
    min-height: 500px;
    min-width: 500px;
    width: 90%;
    resize: both;
    overflow: auto;
}

@media only screen and (max-width: 1100px) {
    .emptyCol {	
        position: relative;	
        width: 300px;	
        height: calc(60% - 20px);	
        margin-right: 100px;	
    }

    .col {	
        position: relative;	
        padding: 10px;	
        width: 300px;	
        margin-right: 100px;	
        height: calc(100% - 20px);	
        background: rgba(255, 255, 255, .4);	
        border-radius: 15px;	
        background-color: rgba(255, 255, 255, .6);  	
        backdrop-filter: blur(8px);	
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); 	
    }
}